<template>
	<v-container fluid class="fill-height">
		<v-container>
			<v-row justify="center" align="center" class="fill-height">
				<v-col cols="12" sm="6" lg="4">
					<v-card color="primary" class="mt-5 pb-5">
						<v-row class="justify-center">
							<v-col class="text-center">
								<img src="./../../assets/toon_4.png" style="width:25%;">
							</v-col>
						</v-row>
						<v-card-title>pardon the interruption</v-card-title>
						<v-card-text>we just need to validate your subscription, please open rewind on the device you subscribed on.</v-card-text>
						<v-card-text>once you have logged in on your device, click refresh below.</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="getUser" color="accent">Refresh</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import {sync, get} from "vuex-pathify";
import RFreeTrialWarning from './components/RFreeTrialWarning'
import RLinkedAccounts from './components/RLinkedAccounts'
import RAccountFooter from './components/RFooter'
import RGrid from './components/RGrid'
import RAccountDeletionWarning from './components/RAccountDeletionWarning'

export default {
	name: "IapCheck",
	metaInfo: {
		title: 'Account',
	},
	computed: {
		user: sync('user/user'),
		iapCheckRequired: get('user/iapCheckRequired')
	},
	mounted() {
		this.getUser()
	},
	methods: {
		getUser() {
			this.$api.users.show()
				.then(response => {
						this.user = response.data
						if (!this.user.iap_check_required) {
							this.$router.push({name: 'Home'})
						}
					}
				)
				.catch(error => console.log(error))
		},
	}
}
</script>